@tailwind base;
@tailwind components;
@tailwind utilities;

Card{
    border-radius: 20;
}

.ql-container {
  border: none !important;
}

.ql-toolbar {
  border: none !important;
}

.ql-editor {
  border: none !important;
  box-shadow: none !important;
}